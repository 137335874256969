import React from "react";
import theme from "../theme";

import Content from "../components/Content.js";
import PageContent from "../components/PageContent.js";
import HomeSection from "../components/HomeSection.js";
import Header from "../components/Header.js";
import PicTwo from "../images/home/Developing control over ecosystems bigdataimg.png";
import PicThree from "../images/home/Evolution of cooperation and conflict network_sketch.png";
import PicOne from "../images/home/Learning causality from large data sets timeseries.png";

function Home() {
  return (
    <Content>
      <Header />
      <PageContent>
        <HomeSection
          id="One"
          color={theme.secondary}
          title="Causal Health Effects"
      text="We are collecting data from hospitalized patients to uncover how the gut ecosystem affects our health. High-resolution, multi-modal time series data recorded during tightly regimented therapies, such as immunotherapies to fight cancer, enable novel causal inference techniques that leverage external perturbations to the microbiome and immune system as ‘natural experiments’. Recognizing this opportunity, we are developing machine learning and Bayesian causal inference techniques to quantify the impact of microbial populations in the gut on the immune system dynamics directly in patients."
      link1="https://doi.org/10.1038/s41467-022-33395-6"
      year1="2022"
      article1="Nature Communications"
      link2="http://dx.doi.org/10.1038/s41591-022-01702-9"
      year2="2022"
      article2="Nature Medicine"
      link3="https://www.nature.com/articles/s41586-020-2971-8"
      year3="2020"
      article3=" Nature"
      photo={PicOne}
        />
        <HomeSection
          id="Two"
          orderText="10"
          orderDiagram="-1"
          color={theme.tertiary}
          title="Ecological Therapeutics"
      text="Microbes evolve to compete well within an ecosystem. This is important to consider especially in the gut microbiome where many different microbial genotypes meet in vast and dense populations. As microbiome research is entering the phase of first FDA-approved therapeutics, a deeper understanding of the ecological implications of evolved competition is crucial. We are dissecting how microbial warfare and human diet can change ecosystem dynamics. To do this, we use patient data, AI-tools, experiments and <i>in vivo</i> models. Our goal is the development of microbiome-targeted drugs with favorable safety profiles so as to accelerate clinical testing."
          link1="https://www.science.org/doi/full/10.1126/science.adk1183"
          year1="2024"
          article1="Science"
          link2="https://doi.org/10.1016/j.chom.2023.05.027"
          year2="2023"
          article2="Cell Host & Microbe"
          link3="https://www.sciencedirect.com/science/article/pii/S1931312816300622"
          year3="2016 "
          article3="Cell Host &amp; Microbe"
          photo={PicTwo}
        />
        <HomeSection
          id="Three"
          color={theme.primary}
          title="Microbiome Evolution"
      text="Competition between microbial genotypes can extinguish host benefits from the microbiome. This is categorically different from organs of the human body, which have a close evolutionary tie with the organism’s fitness. Evolutionary biology offers frameworks to understand the form and function of the microbiome, but testing their predictions can be challenging. We are approaching the relationship between host and microbiome from the perspective of evolutionary game theory, focusing in particular on scenarios where symbiont and host fitness are not aligned. Fundamental understanding of the conflicted relationship between host and microbiota will open up completely novel opportunities for microbiome medicine."
          link1="https://www.nature.com/articles/nature23292"
          year1="2017"
          article1=" Nature"
          link2="https://www.nature.com/articles/nature23292"
          year2="2015"
          article2=" Science"
          link3="https://www.nature.com/articles/nature23292"
          year3="2012"
          article3=" PLoS Biology"
          photo={PicThree}
        />
      </PageContent>
    </Content>
  );
}

export default Home;
