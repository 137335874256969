import React, { useState } from "react";
import styled from "@emotion/styled";

const PersonContainer = styled.div`
  display: inline-block;
  width: 40%;
  margin: 0 5% 10% 5%;

  @media (max-width: 1100px) {
    width: 46%;
    margin: 0 2% 10% 2%;
  }
  @media (max-width: 500px) {
    width: 90%;
  }
`;

const PreviousPersonContainer = styled.div`
  display: inline-block;
  width: 25%;
  margin: 0 2.5% 5% 2.5%;

  @media (max-width: 550px) {
    width: 46%;
    margin: 0 2% 10% 2%;
  }
  @media (max-width: 250px) {
    width: 90%;
  }
`;

const Photo = styled.img`
  border-radius: 100px;
  width: 50%;
  margin: 5% auto;
  border: none;
`;

const PhotoPrevious = styled.img`
  border-radius: 100px;
  width: 20%;
  margin: 5% auto;
  border: none;
`;

const Name = styled.h4`
  border-bottom: 2px solid black;
  padding: 0rem;
  text-align: left;
`;

const PreviousName = styled.h5`
  border-bottom: 2px solid black;
  padding: 0rem;
  text-align: left;
  text-transform: capitalize;
  font-size: 1.5em;
`;

const Button = styled.p`
  text-decoration: underline;
  margin: 0rem 0rem;
`;

const Description = styled.p`
  position: relative;
  margin: 0rem 0rem;
  overflow: hidden;
  white-space: ${({ expanded }) => (expanded ? "normal" : "nowrap")};

  /* Only apply the horizontal fade-out when collapsed */
  ${({ expanded }) =>
    !expanded &&
    `
      &:after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 22rem; /* adjust width as needed for the fade effect */
          pointer-events: none;
          background: linear-gradient(to left, rgba(255,255,255,0.75), rgba(255,255,255,0));
      }
`}
`;

const FlexArticle = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: 1100px) {
    flex-direction: column-reverse;
  }
`;

function Person(props) {
  // State to control whether the description is expanded.
  const [expanded, setExpanded] = useState(false);

  const toggleDescription = () => {
    setExpanded(!expanded);
  };

  return (
    <PersonContainer>
      <Photo src={props.photo} alt={props.name} />
      <Name>{props.name}</Name>
      <Description expanded={expanded}>
        {props.description}
        {props.children}
      </Description>
      <Button onClick={toggleDescription}>
        {expanded ? "Show Less" : "Read More"}
      </Button>
    </PersonContainer>
  );
}

export function PreviousPerson(props) {
  return (
    <PreviousPersonContainer>
      <PhotoPrevious src={props.photo} alt={props.name} />
      <PreviousName>Previous member: {props.name}</PreviousName>
    </PreviousPersonContainer>
  );
}

export function PreviousPersonNoPic(props) {
  return (
    <PreviousPersonContainer>
      <PreviousName>Previous member: {props.name}</PreviousName>
    </PreviousPersonContainer>
  );
}

export default Person;
