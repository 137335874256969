import React from "react";

import Content from "../components/Content.js";
import PageContent from "../components/PageContent.js";
import Person from "../components/Person.js";

import PhotoJonas from "../images/people/jonas24bwcloser.png";
function Jonas() {
return (
    <Content>
    <PageContent>
    <h2>Jonas Schluter</h2>
    <div>
    <div className="flex">
        <Person photo={PhotoJonas} name={"Jonas Schluter, PhD"} description={ "Jonas is tenure-track Assistant Professor at the Institute for Systems Genetics and Department for Microbiology at NYU Langone Health. With a background in microbial biotechnology and mathematical biology, he joined the lab of Kevin Foster at Oxford University. During his PhD, he developed ecological and evolutionary models of the gut microbiota, and built computer simulations of gut epithelium-associated microbial populations. He studied game-theory as an independent JSPS research fellow in Hisashi Ohtsuki and Akira Sasaki's group at Sokendai in Japan to investigate evolutionary conflicts in the microbiome. He then joined Joao Xavier's group at Memorial Sloan Kettering Cancer Center in New York City as a Research Scholar. At MSKCC, he studied how the gut microbiota influences the health and immune system of cancer patients, leveraging vast clinical data sets and interventional trials. He is the Principal Investigator of our team." } />
    </div>
    <br />
    </div>
    </PageContent>
    </Content>
);
}

export default Jonas;

