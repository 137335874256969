import React from "react";
import styled from "@emotion/styled";

import Content from "../components/Content.js";
import PageContent from "../components/PageContent.js";
import Outing1_1 from "../images/lab/outing1_1.jpg";
import Outing1_2 from "../images/lab/outing1_2.jpg";
import Outing1_3 from "../images/lab/outing1_3.jpg";
import Outing1_4 from "../images/lab/outing1_4.jpg";
import Outing1_5 from "../images/lab/outing1_5.jpg";
import Outing1_6 from "../images/lab/outing1_6.jpg";
import Outing2_1 from "../images/lab/outing2_1.jpg";
import Outing2_2 from "../images/lab/outing2_2.jpg";
import Outing2_3 from "../images/lab/outing2_3.jpg";
import Outing2_4 from "../images/lab/outing2_4.jpg";
import Outing2_5 from "../images/lab/outing2_5.jpg";
import Outing2_6 from "../images/lab/outing2_6.jpg";
import Outing2_7 from "../images/lab/outing2_7.jpg";
import Outing2_8 from "../images/lab/outing2_8.jpg";
import Outing2_9 from "../images/lab/outing2_9.jpg";

const SocialContainer = styled.section`
margin-bottom: 50px;
`;
const FotoDescription = styled.div`
    @media (max-width: 500px) {
    width: 90%;
    }`;
const FotoBox = styled.div`
display: flex;
flex-wrap: wrap;
`;

const FotoSocial = styled.img`
width: 31%;
height: 31%;
margin: 1%;

@media (max-width: 1200px) {
width: 48%;
height: 48%;
}
@media (max-width: 500px) {
width: 90%;
height: 90%;
}
`;

function Social() {
return ( < Content>
    < PageContent>
    < FotoDescription>
         < p>
         We spend most of our time in the heart of New York City. Sometimes it can be nice to escape a bit. We are trying to have a regular lab lunch somewhere nice, and escape the town for some outings.
         < /p>
         <h5> 2025/03/31: Photos are being updated </h5>
    </FotoDescription>
    < / PageContent>
    < /Content>
);
}

export default Social;
