import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
//import Analytics from "react-router-ga";
//<Analytics id="UA-64421346-7"></Analytics>
import { Global, css } from "@emotion/core";

import theme from "./theme";
import ScrollToTop from "./components/ScrollToTop.js";

import Home from "./pages/Home";
import Publications from "./pages/Publications";
import People from "./pages/People";
import Jonas from "./pages/Jonas";
import Alexis from "./pages/Alexis";
import Caichen from "./pages/Caichen";
import Wataru from "./pages/Wataru";
import Will from "./pages/Will";
import Hershy from "./pages/Hershy";
import Fanny from "./pages/Fanny";
import Nora from "./pages/Nora";
import EricNishimoto from "./pages/EricNishimoto";
import Barbara from "./pages/Barbara";
import Damee from "./pages/Damee";
import Dipta from "./pages/Dipta";
import Lily from "./pages/Lily";
import Lab from "./pages/Lab";
import Social from "./pages/Social";
import Contact from "./pages/Contact";
import Apply from "./pages/Apply";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Global
        styles={css`
          * {
            box-sizing: border-box;
            font-size: 10px;
            margin: 0;
            padding: 0;
            border: none;
            -webkit-tap-highlight-color: transparent;
            box-shadow: 0 0 0px rgba(0, 0, 0, 0) !important;
            cursor: default;
            &:focus {
              outline: 0px;
            }
            @media (max-width: 1100px) {
              font-size: 9px;
            }
            @media (max-width: 768px) {
              font-size: 8px;
            }
            @media (max-width: 500px) {
              font-size: 8px;
            }
          }

          body {
            height: 100vh;
            min-width: 300px;
            background-color: ${theme.background};
            font-family: helvetica, sans-serif;
            letter-spacing: 1px;
            color: ${theme.text};
          }

          .flex {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
          }

          .center {
            justify-content: center;
          }

          .verticalalign {
            align-items: center;
          }

          .space {
            justify-content: space-between;
          }

          h1 {
            font-size: 2.5rem;
            font-weight: 300;
            padding: 0.5rem;
          }

          h2 {
            font-size: 2.3rem;
            line-height: 3.2rem;
            text-transform: uppercase;
            font-weight: bold;
            padding: 0.5rem;
            padding-bottom: 2rem;
          }

          h3 {
            font-size: 1.7rem;
            line-height: 3rem;
            text-transform: uppercase;
            font-weight: bold;
            padding: 0 3rem;
            transition: 0.6s;
            color: ${theme.tertiary};
            &:hover {
              color: ${theme.text};
            }
          }

          h4 {
            font-size: 1.8rem;
            line-height: 3rem;
            font-weight: bold;
            padding: 0.5rem;
            border-bottom: 2px solid;
            margin-bottom: 1rem;
          }

          h5 {
            font-size: 1.5rem;
            line-height: 3rem;
            text-transform: uppercase;
            font-weight: bold;
            padding: 0.5rem;
          }

          h6 {
            font-size: 1.5rem;
            line-height: 2.5rem;
            font-weight: bold;
            padding: 0.5rem;
          }

          p {
            font-size: 1.4rem;
            line-height: 2rem;
            font-weight: 300;
            padding: 0.5rem 1rem;
          }

          i {
            color: inherit;
            font-size: inherit;
          }

          b {
            color: inherit;
            font-size: inherit;
          }

          a {
            color: inherit;
            font-size: inherit;
            font-family: inherit;
            text-decoration: none;
            cursor: pointer;
          }

          .extlink {
            text-decoration: underline;
          }

          .activeLink {
            color: ${theme.text};
          }

          .applyButton {
            font-size: 1.7rem;
            line-height: 3rem;
            text-transform: uppercase;
            font-weight: bold;
            border-radius: 5px;
            padding: 0 3rem;
            color: ${theme.background};
            background-color: ${theme.secondary};
            transition: 0.3s;
            &:hover {
              background-color: ${theme.primary};
            }
          }

          .activeButton {
            cursor: pointer;
            border-radius: 5px;
            color: ${theme.background};
            background-color: ${theme.primary};
          }

          a:hover {
            cursor: pointer;
          }

          li {
            font-size: 1.4rem;
            line-height: 2rem;
            font-weight: 300;
            padding: 0.5rem;
          }

          //header circles rotation

          .ferris-wrapper {
            cursor: pointer;
            width: 33rem;
            height: 33rem;
            margin: 0;
            &:hover {
              .wheel,
              .cabin {
                animation-play-state: paused;
              }
            }
          }

          .wheel {
            cursor: pointer;
            width: 32.5rem;
            height: 30rem;
            transform-origin: 16.2rem 17.9rem;
            animation: ferri-wheel 25s linear infinite;
          }

          .cabin {
            cursor: pointer;
            animation: cabin 25s linear infinite;
          }

          @keyframes ferri-wheel {
            100% {
              transform: rotate(360deg);
            }
          }

          @keyframes cabin {
            0% {
              transform: rotate(0deg);
            }

            100% {
              transform: rotate(-360deg);
            }
          }

          // burger menu

          /* Position and sizing of burger button */
          .bm-burger-button {
            position: fixed;
            width: 28px;
            height: 22px;
            right: 36px;
            top: 36px;
          }

          /* Color/shape of burger icon bars */
          .bm-burger-bars {
            background: ${theme.primary};
          }

          /* Color/shape of burger icon bars on hover*/
          .bm-burger-bars-hover {
            background: ${theme.primary};
          }

          /* Position and sizing of clickable cross button */
          .bm-cross-button {
            height: 24px;
            width: 24px;
          }

          /* Color/shape of close button cross */
          .bm-cross {
            background: ${theme.secondary};
          }

          .bm-menu-wrap {
            position: fixed;
            height: 100%;
            width: 100%;
          }

          /* General sidebar styles */
          .bm-menu {
            background: ${theme.background};
            padding: 2.5em 1.5em 0;
            font-size: 1.15em;
          }

          /* Wrapper for item list */
          .bm-item-list {
            color: ${theme.background};
            padding: 2em;
          }

          /* Individual item */
          .bm-item {
            display: block;
          }

          /* Styling of overlay */
          .bm-overlay {
            background: ${theme.background};
          }
        `}
      />
      <main>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/people" component={People} />
          <Route path="/people/Jonas" component={Jonas} />
          <Route path="/people/Alexis" component={Alexis} />
          <Route path="/people/Caichen" component={Caichen} />
          <Route path="/people/Wataru" component={Wataru} />
          <Route path="/people/Hershy" component={Hershy} />
          <Route path="/people/Fanny" component={Fanny} />
          <Route path="/people/Will" component={Will} />
          <Route path="/people/EricNishimoto" component={EricNishimoto} />
          <Route path="/people/Damee" component={Damee} />
          <Route path="/people/Nora" component={Nora} />
          <Route path="/people/Dipta" component={Dipta} />
          <Route path="/people/Barbara" component={Barbara} />
          <Route path="/people/Lily" component={Lily} />
          <Route path="/publications" component={Publications} />
          <Route path="/lab" component={Lab} />
          <Route path="/social" component={Social} />
          <Route path="/apply" component={Apply} />
          <Route path="/contact" component={Contact} />
        </Switch>
      </main>
    </Router>
  );
}

export default App;
