import React from "react";
import styled from "@emotion/styled";

import theme from "../theme";

/*height of newsbullet was 72%*/
const NewsBox = styled.ul`
list-style: none;
height: 72%;
width: 250px;
`;

const NewsBullet = styled.li`
lposition: relative;
padding: 5px;
&:before {
content: "";
width: 8px; //used to be 16
height: 8px; //used to be 16
border-radius: 50%;
background: ${theme.text};
position: absolute;
left: -15px;
transform: translateY(75%);
}
`;


function News() {
return (
<div>
    <h5>news</h5>
        <NewsBox>
        <NewsBullet>
        <a className="extlink" href="https://www.science.org/doi/full/10.1126/science.adk1183">
        Nora's paper in <i>Science</i>!
    </a>
        </NewsBullet>
        <NewsBullet>
        <a className="extlink" href="/people/Wataru">
        Wataru won the ASCO Young Investigator Award!
    </a>
        </NewsBullet>
        <NewsBullet>
        <a className="extlink" href="/people/Damee">
        Damee Moon, MD/PhD student, joins the lab!
    </a>
        </NewsBullet>
        <NewsBullet>
        <a className="extlink" href="/people/Eric">
        Welcome to the lab, Eric Nishimoto!
    </a>
        </NewsBullet>
        <NewsBullet>
        <a className="extlink" href="/people/Nora">
        LSRF Fellow Nora Pyenson joins the lab!
    </a>
        </NewsBullet>
        <NewsBullet>
        <a className="extlink" href="https://www.cell.com/cell-host-microbe/issue?pii=S1931-3128(22)X0008-5#">
        Cover of <i>Cell Host & Microbe</i>!
        </a>
        </NewsBullet>
        <NewsBullet>
        <a className="extlink" href="/people/Fanny">
        Fanny is a Helen Hay Whitney Fellow!
        </a>
        </NewsBullet>
        <NewsBullet>
        <a className="extlink" href="https://twitter.com/NIHDirector/status/1590370012459827201">
        The NIH Director highlighted our work! 
        </a>
        </NewsBullet>
        <NewsBullet>
        <a className="extlink" href="/people/Lily">
        Lily's paper in <i>Nature Comms</i>. 
        </a>
        </NewsBullet>
        <NewsBullet>
        <a className="extlink" href="https://www.nature.com/articles/s41591-022-01702-9">
        New paper in <i>Nature Medicine</i>. 
        </a>
        </NewsBullet>
        <NewsBullet>
        <a className="extlink" href="https://www.diversigen.com/grant-program/">
        We won the Expand into Multiomics Grant! 
        </a>
        </NewsBullet>
        <NewsBullet>
        <a className="extlink" href="https://grants.nih.gov/grants/guide/pa-files/PAR-20-259.html">
        Won the NIH New Innovator Award! 
        </a>
        </NewsBullet>
    </NewsBox>
</div>
);
}

export default News;
