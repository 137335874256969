// PreviousPeopleList.js
import React from "react";
import styled from "@emotion/styled";

const PreviousList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const PreviousListItem = styled.li`
  margin: 0.5rem;
  text-align: center;
  
  /* Three columns on wide screens */
  width: calc(33.33% - 1rem);
  
  @media (max-width: 800px) {
    /* Two columns on medium screens */
    width: calc(50% - 1rem);
  }
  
  @media (max-width: 500px) {
    /* Full width on small screens */
    width: 100%;
  }
`;

function PreviousPeopleList() {
  const previousPeople = [
      "Brandon Cai (MSTP rotation student",
      "Frank Chen (visiting, Courant Institute)",
      "Sigri Kløve (visiting student)",
      "Jorick Bater (rotation student)",
      "Emily Dembinsky (rotation student)",
    "Joseph Jae-Suk Kim (rotation student)",
    "Grant A. Hussey (grad student)",
    "Brian Chirn (grad student)",
    "Shiney Chandragati (volunteer)",
    "Kim Zaldana (rotation student)",
    "Karina Flores (rotation student)"
  ];
  
  return (
    <PreviousList>
      {previousPeople.map((name, index) => (
        <PreviousListItem key={index}>
          {name}
        </PreviousListItem>
      ))}
    </PreviousList>
  );
}

export default PreviousPeopleList;
